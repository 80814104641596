import { useContext } from "react";
import { Context } from "../context/DataContext";
import { UseMonthlyIncome } from "../server/fetcher";
import Loading from "../components/Loading";
// import SearchBox from "../components/common/SearchBox";
// import CustomerCard from "../components/CustomerCard";
// import { RemoveAccents } from "../functions";
// import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";
// import useDebounce from "../hooks/useDebounce";
// import BottomNavigationPanel from "../components/BottomNavigationPanel";
// import Userslogin from "./components/UsersLogin";
import IncomeChart from "../components/IncomeChart";

const Panel = () => {
  const { state } = useContext(Context);
  const { user } = state;

  const { data: monthlyIncome, isLoading } = UseMonthlyIncome(user.user_id);
  return (
    <div className="mt-2 mb-5">{isLoading ? <Loading /> : <IncomeChart monthlyIncome={monthlyIncome.reverse()} />}</div>
  );
};

export default Panel;

import { init as initialize, setUser } from "@sentry/react";
// import LogRocket from "logrocket";
// import setupLogRocketReact from "logrocket-react";
import { Integrations } from "@sentry/tracing";
import { toast } from "react-toastify";
import { localStorageKey } from "./config";
import pkg from "../package.json";

function init() {
  initialize({
    dsn: "https://cbb31bc5eaae48c5a8bd7d0d01fe597f@o637002.ingest.sentry.io/5529974",
    release: "admin-" + pkg.version,
    environment: process.env.REACT_APP_ENV,

    integrations: [new Integrations.BrowserTracing()],
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

  // LogRocket.init("9dwevc/wbo-main-j5qyl", {
  //   release,
  // });

  // setupLogRocketReact(LogRocket);
}

function identifyUser() {
  const user = getLocalData(localStorageKey)?.device;

  if (user) {
    console.log("Itenfied user as ", user);
    // LogRocket.identify(user.user_id, {
    //   name: user.firstname + " " + user.lastname,
    // });
    setUser({
      id: user.user_id,
      username: user.username,
      name: user.firstname + " " + user.lastname,
    });
  }
}

function getLocalData(key) {
  try {
    const jsonValue = localStorage.getItem(key);
    const data = jsonValue != null ? JSON.parse(jsonValue) : null;

    return data;
  } catch (e) {
    return null;
  }
}

function spanishErrors(error) {
  let $arr = [];
  $arr["Network Error"] = "Error Conexion de Internet!";
  $arr["timeout of 15000ms exceeded"] = "Tiempo de espera de 15 segundos excedido!";

  return $arr[error] ? $arr[error] : error;
}

function log(message) {
  console.log(message);
  // sentryLog(message);
}

function error(message) {
  console.error(message);
  toast.error(spanishErrors(message), { hideProgressBar: true, autoClose: 8000 });
  // sentryLog(message);
}

// function sentryLog(message) {
//   if (isProduction) {
//     Sentry.captureException(message);
//   }
// }

const exportDefault = { init, log, error, identifyUser };
export default exportDefault;

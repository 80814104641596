import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import SearchBox from "../components/common/SearchBox";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
// import PersonIcon from "@material-ui/icons/Person";
// import NumberFormat from "react-number-format";
// import CollectedPaymentMenu from "../components/CollectedPaymentMenu";
// import DateRangePicker from "../components/DateRangePicker";
// import { Link } from "react-router-dom";
import { RemoveAccents } from "../functions";
// import { getCollectedLoans } from "../context/Selectors";
// import BottomNavigation from "../components/BottomNavigation";
import { UseCobroRoutesInfo } from "../server/fetcher";
import Loading from "../components/Loading";
// import NoInternetContent from "../components/NoInternetContent";

const RoutesCobro = () => {
  const { state } = useContext(Context);
  const [searchText, setSearchText] = useState("");
  // const [currentData, setCurrentData] = useState({ amount: 0, count: 0, list: [] });
  const { user } = state;
  // const [currentDates, setCurrentDates] = useState(null);

  const { data: cobroList, isLoading } = UseCobroRoutesInfo(user.user_id);

  if (isLoading) return <Loading />;
  // if (!collected) return <NoInternetContent />;

  const getFilteredDebtor = (debtors) => {
    let filtered = debtors;

    if (searchText.length > 3) {
      filtered = filtered.filter(
        (m) => RemoveAccents(m.searchName).toUpperCase().indexOf(searchText.toUpperCase()) >= 0
      );
    }

    return filtered;
  };

  const loansFilter = getFilteredDebtor(cobroList);

  const handleSearch = (query) => {
    setSearchText(query);
  };

  return (
    <>
      <div className="mt-3">
        <Box component="div" p={1}>
          <Typography variant="h6" component="h4">
            Cobro Route Info
            {/* <NumberFormat value={currentData.amount} displayType={"text"} thousandSeparator={true} prefix={" $"} /> */}
          </Typography>
          {/* <DateRangePicker setCurrentDate={setCurrentDates} date={currentDates} /> */}
          <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar Prestamo..." value={searchText} />
        </Box>

        <div style={{ maxHeight: 800, width: "100%", overflow: "scroll" }}>
          <table style={{ minWidth: 600 }} className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Owner</th>
                <th scope="col">User</th>
                <th scope="col">Route</th>
                <th scope="col">collect_date</th>
                <th scope="col">inTime</th>
                <th scope="col">outTIme</th>
                <th scope="col">version</th>
                <th scope="col">Env</th>
              </tr>
            </thead>

            <tbody>
              {loansFilter.map((item, index) => (
                <tr key={index} className={!item.outTime ? "text-danger" : ""}>
                  <th scope="row">{index + 1}</th>
                  <td>W{item.owner_id}</td>
                  <td>W{item.user_id}</td>
                  <td>R{parseInt(item.creditor_id)}</td>
                  <td>{item.collect_date}</td>
                  <td>{item.inTime}</td>
                  <td>{item.outTime}</td>
                  <td>{item.version}</td>
                  <td>{item.env}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default RoutesCobro;

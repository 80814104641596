import { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Context } from "../context/DataContext";

const ProtectedRoute = ({ component: Component, render, ...rest }) => {
  const { state } = useContext(Context);
  const { user } = state;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.user_id !== 0) {
          return Component ? <Component {...props} /> : render(props);
        } else {
          return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
        }
      }}
    />
  );
};

export default ProtectedRoute;

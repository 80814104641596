import axios from "axios";
import logger from "../logService";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 60000,
});

instance.interceptors.response.use(null, (ex) => {
  if (ex.response) {
    logger.error(ex.response.data.message);
    console.log(ex.response.data.message);
  } else {
    logger.error(ex.message);
    logger.log(ex.message);
  }
  return Promise.reject(ex);
});

// axios.interceptors.request.use(
//   (config) => {
//     const { origin } = new URL(config.url);
//     const allowedOrigins = [process.env.REACT_APP_API_URL];
//     if (allowedOrigins.includes(origin)) {
//       const token = localStorage.getItem("token");
//       config.headers.authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

const exportDefault = {
  get: instance.get,
  post: instance.post,
  put: instance.put,
  delete: instance.delete,
};

export default exportDefault;

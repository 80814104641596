import { TextField, Select } from "formik-material-ui";
import { Grid, InputLabel, FormControl, MenuItem } from "@material-ui/core";
import { Field } from "formik";

const DOB = ({ labelM, labelD, labelY }) => {
  let days = [];
  let i;
  for (i = 1; i <= 31; i++) {
    days.push(i);
  }

  return (
    <>
      Fecha de Nacimiento
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor={"month"}> {labelM}</InputLabel>
            <Field
              variant={"standard"}
              component={Select}
              key={"month"}
              name={"month"}
              inputProps={{
                name: "month",
                id: "month",
              }}
            >
              <MenuItem key={"default"} value={"00"}>
                Que mes?
              </MenuItem>
              <MenuItem key={"01"} value={"01"}>
                Enero
              </MenuItem>
              <MenuItem key={"02"} value={"02"}>
                Febrero
              </MenuItem>
              <MenuItem key={"03"} value={"03"}>
                Marzo
              </MenuItem>
              <MenuItem key={"04"} value={"04"}>
                Abril
              </MenuItem>
              <MenuItem key={"05"} value={"05"}>
                Mayo
              </MenuItem>
              <MenuItem key={"06"} value={"06"}>
                Junio
              </MenuItem>
              <MenuItem key={"07"} value={"07"}>
                Julio
              </MenuItem>
              <MenuItem key={"08"} value={"08"}>
                Agosto
              </MenuItem>
              <MenuItem key={"09"} value={"09"}>
                Septiembre
              </MenuItem>
              <MenuItem key={"10"} value={"10"}>
                Octubre
              </MenuItem>
              <MenuItem key={"11"} value={"11"}>
                Noviembre
              </MenuItem>
              <MenuItem key={"12"} value={"12"}>
                Diciembre
              </MenuItem>
            </Field>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel htmlFor={"day"}> {labelD}</InputLabel>
            <Field
              variant={"standard"}
              component={Select}
              key={"day"}
              name={"day"}
              inputProps={{
                name: "day",
                id: "day",
              }}
            >
              <MenuItem key={"default"} value={"00"}>
                Que dia?
              </MenuItem>

              {days.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Field>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <Field
            fullWidth
            variant="standard"
            component={TextField}
            key={"year"}
            type={"text"}
            name={"year"}
            label={labelY}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default DOB;

import { useContext } from "react";
import Container from "@material-ui/core/Container";
import { Redirect } from "react-router-dom";
// import { useLocation } from "react-router";
import { Context } from "../context/DataContext";
import RenderLoginForm from "../components/RenderLoginForm";
import { useHistory } from "react-router-dom";

const Login = () => {
  const history = useHistory();
  const { state, signinUser } = useContext(Context);
  const { user } = state;
  // const location = useLocation();

  if (user.user_id !== 0) return <Redirect to="/dashboard" />;

  // const destination = location.state ? location.state.from : "/dashboard";
  const submithandler = (data) => signinUser(data, history.replace);

  return (
    <Container fixed maxWidth="sm">
      <RenderLoginForm submithandler={submithandler} />
    </Container>
  );
};

export default Login;

import useSWR from "swr";
import backend from "./backend";

const defaultOptions = {
  dedupingInterval: 10 * 1000,
  focusThrottleInterval: 10 * 1000,
  errorRetryCount: 3,
  refreshInterval: 15 * 600000,
};

const fetcher = async (url) => {
  try {
    const res = await backend.get(url);
    return res.data;
  } catch (err) {
    console.log(`Network Error, url ${url}`);
    console.log(err.message);
  }
};

export const UseRouteAccessCodes = (creditor_id) => {
  const endPoint = creditor_id ? `/route/access/codes/${creditor_id}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !data, failed: !data && !!error, error, mutate, key: endPoint };
};

export const UseMonthlyIncome = (user_id, limit = 6) => {
  const endPoint = user_id ? `/monthly/income/${limit}` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, { ...defaultOptions });

  return { data, isLoading: !error && !data, error, mutate, key: endPoint };
};

export const UseCustomerList = (user_id) => {
  const endPoint = user_id ? `/customers/listv2` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 6000 * 60,
    refreshInterval: 60000 * 30,
  });

  return { data, isLoading: !error && !data, error, mutate, key: endPoint };
};

export const UseCobroRoutesInfo = (user_id) => {
  const endPoint = user_id ? `/cobro/routes/info` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 6000 * 15,
    refreshInterval: 60000 * 20,
  });

  return { data, isLoading: !error && !data, error, mutate, key: endPoint };
};

export const UsePaymentList = (user_id) => {
  const endPoint = user_id ? `/payments/list` : null;

  const { data, error, mutate } = useSWR(endPoint, fetcher, {
    ...defaultOptions,
    dedupingInterval: 6000 * 15,
    refreshInterval: 60000 * 20,
  });

  return { data, isLoading: !error && !data, error, mutate, key: endPoint };
};

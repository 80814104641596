import { format } from "date-fns";
import { es } from "date-fns/locale";

export function printDate(date, formatStr = "dd-MMM-yy") {
  if (!date) return "Ninguno Aun";
  return format(date, formatStr, { locale: es });
}

// parse a date in yyyy-mm-dd format
export function newDate(input) {
  if (input === "1970-01-01") return null;
  if (!input) return console.log("Input date not set!");
  if (input.length !== 10) return console.log(`wrong input provided for newDate ${input}`);
  let parts = input.split("-");
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

export function printAbsAmount(amount, sign = "$") {
  const pieces = parseFloat(Math.abs(amount)).toFixed(0).split("");
  let ii = pieces.length - 0;
  while ((ii -= 3) > 0) {
    pieces.splice(ii, 0, ",");
  }
  return sign + pieces.join("");
}

export function generateContract(str, data, bold = true) {
  return str.replace(/%\w+%/g, function (all) {
    if (bold) return all in data ? "<b>" + data[all] + "</b>" : all;
    return all in data ? data[all] : all;
  });
}

export function getTimestamp() {
  return Math.floor(Date.now() / 1000);
}

export function getLocalData(key) {
  try {
    const jsonValue = localStorage.getItem(key);
    const data = jsonValue != null ? JSON.parse(jsonValue) : null;

    return data;
  } catch (e) {
    return null;
  }
}

// export const asyncLocalStorage = {
//   setItem: async function (key, value) {
//     await null;
//     try {
//       const jsonValue = JSON.stringify(value);
//       localStorage.setItem(key, jsonValue);
//     } catch (err) {
//       console.log(err);
//     }
//   },
//   getItem: async function (key) {
//     await null;
//     try {
//       const jsonValue = localStorage.getItem(key);
//       return jsonValue != null ? JSON.parse(jsonValue) : null;
//     } catch (err) {
//       console.log("AppWorkflow - getLocalData from dataContext - catch Error");
//       console.log(err);
//     }
//   },
// };

export function searchArray(array, searchText = "") {
  let filtered = array;

  if (searchText.length > 2) {
    filtered = filtered.filter((m) => RemoveAccents(m.name).toUpperCase().indexOf(searchText.toUpperCase()) >= 0);
    return filtered.slice(0, 15);
  }

  return filtered;
}

export function sortArrayObjs(arr, prop1, prop2) {
  let sort1 = [...arr].sort(function (a, b) {
    if (a[prop1] === b[prop1]) {
      if (a[prop2] === b[prop2]) return 0;
      return a[prop2] < b[prop2] ? -1 : 1;
    } else {
      return a[prop1] < b[prop1] ? -1 : 1;
    }
  });
  return sort1;
}

export function sortArrayObjs2(arr, prop1, prop2) {
  let sort1 = [...arr].sort(function (a, b) {
    if (+a[prop1] === +b[prop1]) {
      if (+a[prop2] === +b[prop2]) return 0;
      return +a[prop2] < +b[prop2] ? -1 : 1;
    } else {
      return +a[prop1] < +b[prop1] ? -1 : 1;
    }
  });
  return sort1;
}

export function calcNewCompleted(loan, paymentAmount) {
  return loan.npayments - Math.ceil((loan.balance - paymentAmount) / loan.wPayment);
}

export function calcNewLoanStatus(loan, paymentAmount) {
  let { statusText, statusAmount } = loan;
  if (loan.paymentAmount + paymentAmount === 0) return { statusText, statusAmount };
  if (loan.balance - paymentAmount === 0) return { statusText: "Saldado", statusAmount: 0 };

  if (loan.statusText !== "Vencido") {
    statusAmount = loan.pending * loan.wPayment - loan.paymentAmount - paymentAmount - loan.incomplete;
    if (statusAmount < 0) {
      statusText = "Adelanto";
    } else if (statusAmount > 0) {
      statusText = "Atrasos";
    } else {
      statusText = "Al Dia";
    }
  } else {
    statusAmount = statusAmount - paymentAmount;
  }

  // return { statusAmount: atrasos + 1, statusText: statusText + 2 };
  return { statusAmount, statusText };
}

export function formatPhoneNumber(str) {
  let cleaned = ("" + str).replace(/\D/g, "");
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  return match ? "(" + match[1] + ") " + match[2] + "-" + match[3] : "N/A";
}

export function reduceCollectedPaymentObject(loans) {
  const size = loans.length;
  let newLoans = [];

  var i;
  for (i = 0; i < size; i++) {
    if (loans[i].paymentAmount) {
      newLoans = [
        ...newLoans,
        {
          name: loans[i].name,
          debtor_id: loans[i].debtor_id,
          payment_id: loans[i].payment_id,
          money_id: loans[i].money_id,
          deleted: loans[i].deleted,
          paymentAmount: loans[i].paymentAmountDisplay,
          paymentMora: loans[i].paymentMora,
          // paidFromRenewal: loans[i].paidFromRenewal,
          // timestamp: loans[i].timestamp,
        },
      ];
    }
  }

  return newLoans;
}

export function validateCedula(required = true) {
  return this.test("validateCedula", required, function (value) {
    const { path, createError } = this;

    if (!value) {
      if (!required) return true;
      return createError({ path, message: "Cedula es requeridad!" });
    }

    const c = String(value).replace(/\D/g, "");
    if (c.length !== 11) {
      return createError({ path, message: "Cedula no es validad!" });
    }

    let cedula = c.substr(0, c.length - 1);
    let verify = c.substr(c.length - 1, 1) * 1;
    const cedulaLength = cedula.length;
    let sum = 0;

    for (let i = 0; i < cedulaLength; i++) {
      let mod;
      if (i % 2 === 0) {
        mod = 1;
      } else {
        mod = 2;
      }

      let rest = cedula.substr(i, 1) * mod;

      if (rest > 9) {
        let newRest = String(rest);
        let one = newRest.substr(0, 1) * 1;
        let two = newRest.substr(1, 1) * 1;
        rest = one + two;
      }
      sum += rest;
    }

    const the_number = (10 - (sum % 10)) % 10;
    const initialValues = String(cedula.substr(0, 3));

    if (the_number !== verify && initialValues !== "000") {
      return createError({ path, message: "Cedula no es validad!" });
    }

    return true;
  });
}

export function printAmount(amount, sign = "$") {
  const pieces = parseFloat(amount).toFixed(0).split("");
  let ii = pieces.length - 0;
  while ((ii -= 3) > 0) {
    pieces.splice(ii, 0, ",");
  }
  return sign + pieces.join("");
}

export function concatPhoneLink(phone) {
  if (phone) {
    return `tel:${phone}`;
  }
  return null;
}

export function RemoveAccents(str) {
  if (!str) {
    console.log(`RemoveAccents received undefined.`);
    return;
  }

  var accents = "ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž";
  var accentsOut = "AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz";
  str = str.split("");
  var strLen = str.length;
  var i, x;
  for (i = 0; i < strLen; i++) {
    if ((x = accents.indexOf(str[i])) !== -1) {
      str[i] = accentsOut[x];
    }
  }
  return str.join("");
}
export function getUniqueID() {
  return Date.now();
}

export function getBadgeColor(status) {
  if (status === "Vencido") {
    return "badge badge-pill badge-danger";
  } else if (status === "Atrasos") {
    return "badge badge-pill badge-warning";
  } else if (status === "Adelanto") {
    return "badge badge-pill badge-info";
  } else if (status === "Saldado") {
    return "badge badge-pill badge-secondary";
  } else {
    return "badge badge-pill badge-success";
  }
}

export function forceReload(deleteCache = false) {
  if (deleteCache) {
    // The following codes will delete all caches.
    if (caches) {
      console.log("Cleaning caches");
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
  }

  window.location.reload();
}

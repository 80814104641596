import { useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
// import Divider from "@material-ui/core/Divider";
// import WhatsAppIcon from "@material-ui/icons/WhatsApp";
// import PrintIcon from "@material-ui/icons/Print";
// import { printReceiptHandler } from "../functions/print";
// import { AiOutlineOrderedList } from "react-icons/ai";
import MoreVertIcon from "@material-ui/icons/MoreVert";
// import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { AiOutlineInfoCircle } from "react-icons/ai";
// import VisibilityIcon from "@material-ui/icons/Visibility";
// import { FaReceipt } from "react-icons/fa";

//TODO This component is not being used?
export default function CollectedMenu({ handleDataClick, customer }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteClick = () => {
    handleDataClick(customer);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreVertIcon fontSize="small" />
      </Button>

      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {/* <MenuItem
          className="text-primary"
          onClick={() => history.push(`/loan/detail/${customer.money_id}/${customer.debtor_id}`)}>
          <VisibilityIcon className="pr-1" /> Prestamo Detalle
        </MenuItem>
        <Divider />
        <MenuItem
          // className="text-info"
          onClick={() => history.push(`/loan/payments/${customer.money_id}/${customer.debtor_id}`)}>
          <AiOutlineOrderedList size="1.4em" className="pr-1" /> Historial de Pagos
        </MenuItem>
        <Divider />
*/}
        <MenuItem className="text-info" onClick={handleDeleteClick}>
          <AiOutlineInfoCircle size="1.4em" className="pr-1" /> Ver mas
        </MenuItem>
      </Menu>
    </div>
  );
}

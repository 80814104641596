import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { printAmount, printDate } from "../functions";
// import Chip from "@material-ui/core/Chip";
import { parseISO } from "date-fns";
import { BiBlock } from "react-icons/bi";

const RenderPaymentInfo = ({ customer }) => {
  return (
    <div>
      <Grid container>
        <Grid item xs={10}>
          <Typography variant="h6" component="h4" gutterBottom>
            Aplicar Pago!
          </Typography>
        </Grid>
      </Grid>

      <ul className="list-group mb-3">
        <li className="list-group-item">
          <Typography variant="body1" component="p">
            Cliente ID: <b className="p-2">W{customer.customer_id}</b>
          </Typography>
        </li>

        {+customer.credit > 0 && (
          <li className="list-group-item list-group-item-info">
            <Typography variant="body1" component="p">
              Credito (+): {printAmount(customer.credit)}
            </Typography>
          </li>
        )}

        {+customer.referral > 0 && (
          <li className="list-group-item list-group-item-info">
            <Typography variant="body1" component="p">
              Credito de Referido: {printAmount(customer.referral)}
            </Typography>
          </li>
        )}

        <li className="list-group-item">
          <Typography variant="body1" component="p">
            Monto Mensual: {printAmount(customer.monthly)}
          </Typography>
        </li>

        <li className="list-group-item">
          <Typography variant="body1" component="p">
            &Uacute;ltimo Pago:{" "}
            {customer.lastPaidDate ? printDate(parseISO(customer.lastPaidDate), "dd-MMM-yy") : "Ninguno Ahun"}
          </Typography>
        </li>

        <li className="list-group-item list-group-item-secondary font-weight-bold">
          <Typography variant="body1" component="p">
            Balance Pendiete: {printAmount(customer.balance)}{" "}
            {+customer.balance > +customer.monthly && (
              <span className="text-danger">
                <BiBlock size="1.2em" /> (<b>{customer.count}</b>)
              </span>
            )}
          </Typography>
        </li>
      </ul>
    </div>
  );
};

export default RenderPaymentInfo;

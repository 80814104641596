import { Form, RenderInput, RenderCheckboxLabel } from "./Form";
import { string, object, addMethod, mixed } from "yup";
// import Grid from "@material-ui/core/Grid";
// import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { validateCedula } from "../functions";

const RenderDebtorForm = ({ initialValues, submithandler, button }) => {
  addMethod(mixed, "validateCedula", validateCedula);
  const validation = object({
    name: string()
      .required("Nombre es requerido!")
      .min(6, "Nombre es muy corto!")
      .matches(/^([^0-9]*)$/, "Nombre no puede tener numeros!"),
    phone: string().required("Telefono es requerido!").min(10, "Telefono es muy corto!"),
    cedula: mixed().validateCedula(true),
  });

  return (
    <div>
      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Box paddingBottom={2}>{RenderInput("cedula", "Cedula", "tel")}</Box>
        <Box paddingBottom={2}>{RenderInput("name", "Nombre Completo", "text", true)}</Box>

        <Box paddingBottom={2}>{RenderInput("phone", "Telefono", "tel")}</Box>

        <Box paddingBottom={2}>{RenderInput("address", "Dir. Casa")}</Box>
        <Box paddingBottom={2}>{RenderInput("occupation", "Ocupacion")}</Box>
        <Box paddingBottom={2}>{RenderInput("work_address", "Dir. Trabajo")}</Box>

        <Box paddingBottom={0}>{RenderCheckboxLabel("accurateInfo", "Marcar informacion como correcta!")}</Box>
        <Box paddingBottom={0}>{RenderCheckboxLabel("deleleImage", "Eliminar foto de Cedula!")}</Box>
      </Form>
    </div>
  );
};

export default RenderDebtorForm;

import { useHistory } from "react-router-dom";
import Drawer from "./components/Drawer";
// import ChangeRouteModal from "./components/ChangeRouteModal";
import { ToastContainer } from "react-toastify";
import Snackbar from "./components/Snackbar";

import ResolveAuth from "./pages/ResolveAuth";
import { hot } from "react-hot-loader";
import Paper from "@material-ui/core/Paper";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import "react-toastify/dist/ReactToastify.css";
import { withProfiler } from "@sentry/react";
import Routes from "./Routes.js";
// import Userslogin from "./components/UsersLogin";
// import IncomeChart from "./components/IncomeChart";

const App = () => {
  let history = useHistory();

  // useEffect(() => {
  //   if (navigator.serviceWorker) {
  //     navigator.serviceWorker.addEventListener("message", ({ data }) => {
  //       if (data.type === "CACHE_UPDATED") {
  //         console.log("Calling mutate becase data has been updated.");
  //         mutate();
  //         mutate2();
  //       } else {
  //         console.log("Not Calling mutate becase data has been updated.");
  //       }
  //     });
  //   }
  //   //eslint-disable-next-line
  // }, []);

  const lightTheme = createTheme({
    palette: {
      primary: {
        main: "#23569e",
      },
    },
  });

  return (
    <ThemeProvider theme={lightTheme}>
      {/* <Userslogin />
      <IncomeChart /> */}
      <Paper variant="outlined" style={{ minHeight: "100vh" }}>
        <ResolveAuth history={history} />
        <Drawer history={history} />
        <ToastContainer />
        <Snackbar />
        <Routes history={history} />
      </Paper>
    </ThemeProvider>
  );
};

export default hot(module)(withProfiler(App));

import { Switch, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Panel from "./pages/Panel";
import ProtectedRoute from "./components/ProtectedRoute";
import Login from "./pages/Login";
import Payment from "./pages/Payment";
import Collected from "./pages/Collected";
import Balance from "./pages/Balance";
import Monthly from "./pages/Monthly";
import Referrals from "./pages/Referrals";
import Routes from "./pages/Routes";
import Users from "./pages/Users";
import AccessCodes from "./pages/AccessCodes";
import CedulasManagement from "./pages/CedulasManagement";
import RoutesCobro from "./pages/RoutesCobro";

const Routes2 = () => {
  return (
    <Switch>
      <ProtectedRoute path="/panel" render={(props) => <Panel {...props} />} />
      <ProtectedRoute path="/dashboard" render={(props) => <Dashboard {...props} />} />
      <ProtectedRoute path="/payment/:id" render={(props) => <Payment {...props} />} />

      <ProtectedRoute path="/customers/monthly" render={(props) => <Monthly {...props} />} />
      <ProtectedRoute path="/collected/payments" render={(props) => <Collected {...props} />} />
      <ProtectedRoute path="/customers/balance" render={(props) => <Balance {...props} />} />
      <ProtectedRoute path="/referrals" render={(props) => <Referrals {...props} />} />
      <ProtectedRoute path="/users" render={(props) => <Users {...props} />} />
      <ProtectedRoute path="/routes" render={(props) => <Routes {...props} />} />
      <ProtectedRoute path="/access/codes" render={(props) => <AccessCodes {...props} />} />
      <ProtectedRoute path="/cedulas" render={(props) => <CedulasManagement {...props} />} />
      <ProtectedRoute path="/route/cobro/info" render={(props) => <RoutesCobro {...props} />} />

      <Route path="/" exact render={(props) => <Login {...props} />} />
    </Switch>
  );
};

export default Routes2;

import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import SearchBox from "../components/common/SearchBox";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PersonIcon from "@material-ui/icons/Person";
import { RemoveAccents, printAmount } from "../functions";
// import CollectedPaymentMenu from "../components/CollectedPaymentMenu";
import { Link } from "react-router-dom";
// import BottomNavigationPanel from "../components/BottomNavigationPanel";
import { UseCustomerList } from "../server/fetcher";
import Loading from "../components/Loading";

const Balance = () => {
  const { state } = useContext(Context);
  const { user } = state;
  const [currentData, setCurrentData] = useState([]);
  const [balanceAmount, setBalanceAmount] = useState([]);
  const [searchText, setSearchText] = useState("");

  const { data: customers, isLoading } = UseCustomerList(user.user_id);

  useEffect(() => {
    if (isLoading) return;

    const current = customers.filter((x) => +x.balance > 0);
    setCurrentData(current);

    setBalanceAmount(
      current.reduce((acc, obj) => {
        return +obj.balance > 0 ? acc + +obj.balance : acc;
      }, 0)
    );

    //eslint-disable-next-line
  }, [customers]);

  if (isLoading) return <Loading />;

  const filterCustomers = (customers) => {
    let filtered = customers;

    if (searchText.length > 2) {
      filtered = filtered.filter((m) => RemoveAccents(m.search).toUpperCase().indexOf(searchText.toUpperCase()) >= 0);
      return filtered.slice(0, 5);
    }

    return filtered.slice(0, 50);
  };

  const handleSearch = (query) => {
    setSearchText(query);
  };

  const filtered = filterCustomers(currentData);

  return (
    <>
      {balanceAmount === 0 ? (
        <div className="container mt-5 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Clientes con balance!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            En esta pagina puede ver todo los clientes con balance.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, no hay clientes con balance!
          </Typography>
        </div>
      ) : (
        <div className="mt-3">
          <Box component="div" p={1}>
            <Typography variant="h6" component="h4">
              <span>Balance pendiente: {printAmount(balanceAmount)}</span>
            </Typography>

            <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar Prestamo..." value={searchText} />
          </Box>

          <div className="mb-5">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Balance</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((customer) => (
                  <tr key={customer.customer_id}>
                    <td>
                      <Typography variant="subtitle2" component="p" className="font-weight-bold">
                        <Link style={{ color: "#000" }} to={`/payment/${customer.customer_id}`}>
                          <PersonIcon /> {customer.name}
                        </Link>
                      </Typography>
                    </td>
                    <td>{printAmount(customer.balance)}</td>
                    <td>{customer.balance > customer.monthly && <span className="text-danger">Blocqueado</span>}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* <BottomNavigationPanel customers={customers} /> */}
    </>
  );
};

export default Balance;

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom",
    },
    title: {
      display: true,
      text: "Wprestamos Ingresos!",
    },
  },
};

export default function App({ monthlyIncome }) {
  if (!monthlyIncome) return null;
  const labels = monthlyIncome.map((a) => a.month);

  const data = {
    labels,
    datasets: [
      {
        label: "Cobrado",
        // data: labels.map(() => faker.datatype.number({ min: 0, max: 80000 })),
        data: monthlyIncome.map((a) => a.collected),
        borderColor: "rgb(32,152,102)",
        backgroundColor: "rgba(32,152,102, 0.5)",
      },
      {
        label: "Mensualidad",
        //   data: labels.map(() => faker.datatype.number({ min: 0, max: 80000 })),
        data: monthlyIncome.map((a) => a.monthly),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  return <Line options={options} data={data} />;
}

import React, { useState, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

const SubmitButton = ({ text, startIcon, color = "primary", callBack, autoFetch = true }) => {
  const [isLoading, setLoading] = useState(null);

  useEffect(() => {
    if (!autoFetch) return;
    handleRequest();
    //eslint-disable-next-line
  }, []);

  const handleOnClick = async () => {
    handleRequest();
  };

  const handleRequest = async () => {
    setLoading(true);
    await callBack();
    setLoading(false);
  };

  return (
    <Button
      fullWidth
      disabled={isLoading}
      color={color}
      startIcon={isLoading ? <CircularProgress size="1rem" /> : startIcon}
      onClick={handleOnClick}
      variant="contained">
      {isLoading ? "Por favor, espere!" : text}
    </Button>
  );
};

export default SubmitButton;

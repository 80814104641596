// Data key name for Persisting App State.
export const localStorageKey = "wbo-admin";
export const localStorageRememberUser = "wbo-admin-remeberUser";
export const localStorageToken = "wbo-admin-tokenV2";
export const localStorageAuth = "authToken-admin";
export const localStorageCurrentRoute = "ummYearsggdjjj";

//This constant should be 1 on WPrestamos main & 0 on WPrestamos cobro project.
export const mainApp = 1;
export const projectName = "WPrestamos Admin";

import React, { useState, useContext } from "react";
import { Context } from "../context/DataContext";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionActions from "@material-ui/core/AccordionActions";
import Typography from "@material-ui/core/Typography";
import AccessCodeComponent from "../components/AccessCodeComponent";
import SubmitButton from "../components/SubmitButton";
import { BiRefresh } from "react-icons/bi";
import Grid from "@material-ui/core/Grid";
import Loading from "../components/Loading";
import { UseRouteAccessCodes } from "../server/fetcher";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(AccordionActions);

export default function CustomizedAccordions() {
  const { generateAccessCodes } = useContext(Context);
  const [expanded, setExpanded] = useState("panel1");

  const { data: accessCodes, isLoading, mutate } = UseRouteAccessCodes(100);

  if (isLoading) return <Loading />;

  const handleGenerate = async (type) => {
    const codes = [];
    codes[0] = Math.floor(100000 + Math.random() * 900000);
    // codes[1] = Math.floor(100000 + Math.random() * 900000);

    await generateAccessCodes(100, type, codes);
    mutate([...accessCodes, { code: codes[0], type }]);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className="mt-4 container">
      <Typography variant="h6" gutterBottom>
        Codigos de Acesos
      </Typography>
      <Accordion square expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>Upload App State</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "appState")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            color="primary"
            callBack={() => handleGenerate("appState")}
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>

      <Accordion square expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Upload Syncronization</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "pendingSync")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            color="primary"
            callBack={() => handleGenerate("pendingSync")}
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>

      <Accordion square expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
        <AccordionSummary aria-controls="panel2d-content" id="panel3d-header">
          <Typography>Clear App Data</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container>
            {accessCodes
              .filter((x) => x.type === "clearApp")
              .map((item) => (
                <Grid key={item.type + item.code} item xs={12} sm={6} md={4}>
                  <AccessCodeComponent key={item.type + item.code} code={item.code} />
                </Grid>
              ))}
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <SubmitButton
            text="Generar Codigos"
            startIcon={<BiRefresh />}
            color="primary"
            callBack={() => handleGenerate("clearApp")}
            autoFetch={false}
          />
        </AccordionActions>
      </Accordion>
    </div>
  );
}

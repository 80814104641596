import HomeIcon from "@material-ui/icons/Home";
import PeopleIcon from "@material-ui/icons/People";
// import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
// import SettingsIcon from "@material-ui/icons/Settings";
// import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
// import ThumbUpIcon from "@material-ui/icons/ThumbUp";
// import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
// import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import { FaHandHoldingUsd } from "react-icons/fa";
import { HiClipboardList } from "react-icons/hi";
import { GiPayMoney, GiTakeMyMoney } from "react-icons/gi";
// import { HiDocumentReport } from "react-icons/hi";
// import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";

export const DrawerList = [
  {
    title: "Panel",
    path: "/panel",
    icon: <HomeIcon />,
  },
  {
    title: "Inicio",
    path: "/dashboard",
    icon: <HomeIcon />,
  },
  {
    title: "Referidos",
    path: "/referrals",
    icon: <HiClipboardList size="1.5em" />,
  },
  {
    title: "Usuarios",
    path: "/users",
    icon: <FaHandHoldingUsd size="1.5em" />,
  },
  {
    title: "Rutas",
    path: "/routes",
    icon: <PeopleIcon />,
  },
  {
    title: "Access Codes",
    path: "/access/codes",
    icon: <PeopleIcon />,
  },
  {
    title: "Cedula Publica",
    path: "/cedulas",
    icon: <GiTakeMyMoney size="1.7em" />,
  },
  {
    title: "Rutas Cobro Infro",
    path: "/route/cobro/info",
    icon: <GiPayMoney size="1.5em" />,
  },
  // {
  //   title: "Gastos",
  //   path: "/spenses",
  //   icon: <LocalOfferIcon />,
  // },
  // {
  //   title: "Cuadre",
  //   path: "/cuadre",
  //   icon: <MonetizationOnIcon />,
  // },
  // // {
  // //   title: "Caja",
  // //   path: "/caja",
  // //   icon: <AccountBalanceIcon />,
  // // },
  // // {
  // //   title: "Solicitudes",
  // //   path: "/approval",
  // //   icon: <ThumbUpIcon />,
  // // },
  // // {
  // //   title: "Reportes",
  // //   path: "/reports",
  // //   icon: <HiDocumentReport size="1.7em" />,
  // // },
  // {
  //   title: "Empleados",
  //   path: "/employees",
  //   icon: <PeopleOutlineIcon />,
  // },
  // {
  //   title: "Ajustes",
  //   path: "/settings",
  //   icon: <SettingsIcon />,
  // },
];

import React from "react";
import Typography from "@material-ui/core/Typography";
import RenderShareButtons from "./RenderShareButtons";
import Grid from "@material-ui/core/Grid";

const AccessCodeComponent = ({ code }) => {
  // if (codes.length === 0) return <Typography variant="subtitle1">No Codigos de acesos</Typography>;

  return (
    <>
      <Grid className="mb-2" container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h5">{code}</Typography>
        </Grid>
        <Grid item xs={6}>
          <RenderShareButtons message={code} messageWhatsApp={code} />
        </Grid>
      </Grid>
    </>
  );
};

export default AccessCodeComponent;

import { useParams } from "react-router";
import { FaUserEdit } from "react-icons/fa";
// import Loading from "@shared/components/Loading";
// import { selectCommon } from "../store/slices/common";
import { useHistory } from "react-router-dom";
// import { updateNewDebtor } from "../store/slices/newDebtors";
// import { removeNonUniqueType } from "../store/slices/syncronization";
// import { alertSuccess } from "../store/slices/snackbar";
// import { useGetDebtorsQuery, useUpdateDebtorMutation } from "../store/slices/api";
import RenderDebtorForm from "../components/RenderDebtorForm";
import RenderCedulaImage from "../components/RenderCedulaImage";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
// import RenderBackButton from "@shared/components/RenderBackButton";
// import { getUniqueID } from "@shared/functions";
// import { updateDebtorInfo } from "../store/slices/loans";
// import { updateDebtorNewLoan } from "../store/slices/newLoans";

const CedulasManagement = () => {
  // const { id: dUniqueId } = useParams();
  // const history = useHistory();

  // if (!debtors) return <Loading />;

  // const currentDebtor = debtors.find((x) => x.dUniqueId === dUniqueId);

  const initialValues = {
    name: "",
    phone: "",
    address: "",
    occupation: "",
    work_address: "",
    deleleImage: false,
    accurateInfo: false,
  };

  const button = {
    icon: <FaUserEdit />,
    label: "Actualizar Cliente",
    variant: "contained",
    color: "primary",
  };

  const submithandler = async (data) => {};

  return (
    <div className="container mt-4 mb-5">
      <RenderCedulaImage cedula={null} loading={true} />

      <Typography variant="h5" component="h3" gutterBottom>
        Cedula: 000-000000000-0
      </Typography>

      <Grid container className="mb-3">
        <Grid item xs={6}>
          <Typography variant="body1">Image ID: 12</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body1">Cedula ID: 13</Typography>
        </Grid>
      </Grid>

      <RenderDebtorForm initialValues={initialValues} submithandler={submithandler} button={button} />

      {/* <div className="mt-3">
        <RenderBackButton />
      </div> */}
    </div>
  );
};

export default CedulasManagement;

import { Form as FormikForm, Formik } from "formik";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import TextInput from "./common/TextInput";
import Dropdown from "./common/Dropdown";
import CheckboxLabel from "./common/CheckboxLabel";
import DOB from "./common/DOB";
import useMediaQuery from "../hooks/useMediaQuery";

export function RenderInput(name, label, type = "text", autoFocus = false, variant = "standard", helperText = "") {
  return (
    <TextInput name={name} label={label} type={type} autoFocus={autoFocus} variant={variant} helperText={helperText} />
  );
}

export function RenderDropdown(name, label, list = [], defaultProperty = null, variant = "standard") {
  // Default variant standard because outlined looks bad
  return <Dropdown name={name} label={label} list={list} defaultProperty={defaultProperty} variant={variant} />;
}

export function RenderCheckboxLabel(name, label, color = "primary") {
  return <CheckboxLabel name={name} label={label} color={color} />;
}

export function RenderDOB(labelM, labelD, labelY) {
  return <DOB labelM={labelM} labelD={labelD} labelY={labelY} />;
}

export function Form({ initialValues, validation, button, submithandler, children }) {
  const fullScreen = useMediaQuery("min-width:820px");
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validation}
      onSubmit={async (values) => {
        if (!values.password) console.log("Submitting Form Data: ", values);

        await submithandler(values);
      }}>
      {({ isSubmitting }) => (
        <FormikForm>
          {children}

          <Box>
            <Button
              fullWidth={!fullScreen}
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : button.icon}
              disabled={isSubmitting}
              variant={button.variant}
              type="submit"
              color={button.color}>
              {isSubmitting ? "Por favor, espere!" : button.label}
            </Button>
          </Box>
        </FormikForm>
      )}
    </Formik>
  );
}

import React, { useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();

      if (process.env.NODE_ENV === "development") return;

      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <div className="mt-4 mb-5">
      <Typography variant="h6" component="p" className="p-2">
        Esta applicacion se puede instalar
      </Typography>
      <button
        style={{ width: "100%" }}
        className="mt-2 btn btn-success"
        id="setup_button"
        aria-label="Install app"
        title="Install app"
        onClick={onClick}>
        <i>
          <u>Clic para Instalar App!</u>
        </i>
      </button>
      <Typography variant="subtitle1" component="p" className="p-2">
        Despues que se instale puede abrir la como una aplicacion desde su lista de aplicaciones!
      </Typography>
    </div>
  );
};

export default InstallPWA;

import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import { isAndroid } from "react-device-detect";
import SearchBox from "../components/common/SearchBox";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import { RemoveAccents, printAmount, printDate, concatPhoneLink, sortArrayObjs } from "../functions";
import { Link } from "react-router-dom";
import BottomNavigationPanel from "../components/BottomNavigationPanel";
import { UseCustomerList } from "../server/fetcher";
import Loading from "../components/Loading";
import BalanceMenu from "../components/BalanceMenu";
import Modal from "react-bootstrap/Modal";
// import { BiBlock } from "react-icons/bi";
import { FcCallback, FcSms } from "react-icons/fc";
import { parseISO } from "date-fns";
import { BsWhatsapp } from "react-icons/bs";

function getClassName({ sortValue, balance }) {
  if (+sortValue === 1) return { className: "bg-danger", balance: "Bloqueado" };
  if (+sortValue === 2) return { className: "bg-primary", balance: "Nuevo" };

  return { className: "", balance: printAmount(balance) };
}

const Balance = () => {
  const { state } = useContext(Context);
  const { user } = state;
  const [currentData, setCurrentData] = useState([]);
  const [balanceAmount, setBalanceAmount] = useState([]);
  const [BalanceCount, setBalanceCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [modalData, setModalData] = useState({});

  const { data: customers, isLoading } = UseCustomerList(user.user_id);

  useEffect(() => {
    if (isLoading) return;

    const current = customers.filter((x) => +x.balance > 0 && +x.inactive === 0);
    const sorted = sortArrayObjs(current, "sortValue", "balance");
    setCurrentData(sorted);

    setBalanceAmount(
      current.reduce((acc, obj) => {
        return +obj.balance > 0 ? acc + +obj.balance : acc;
      }, 0)
    );

    setBalanceCount(
      current.reduce((acc, obj) => {
        return +obj.balance > 0 ? acc + 1 : acc;
      }, 0)
    );

    //eslint-disable-next-line
  }, [customers]);

  if (isLoading) return <Loading />;

  const filterCustomers = (customers) => {
    let filtered = customers;

    if (searchText.length > 2) {
      filtered = filtered.filter((m) => RemoveAccents(m.search).toUpperCase().indexOf(searchText.toUpperCase()) >= 0);
      return filtered.slice(0, 5);
    }

    return filtered.slice(0, 50);
  };

  const handleSearch = (query) => {
    setSearchText(query);
  };

  const handleDataClick = (data) => {
    setModalData(data);
  };

  const filtered = filterCustomers(currentData);

  const getMessage = ({ name, balance, monthly, lastPaidDate, customer_id }) => {
    var firstName = name.replace(/ .*/, "");

    const cusUrl = `para mas info ${process.env.REACT_APP_PUBLIC_DOMAIN}/customer/balance/${customer_id}`;

    //Reminder message
    let message = `Saludo ${firstName}, quiero informarle que tiene un balance pendiente en WPrestamos. Su balance es ${printAmount(
      balance
    )} pesos por favor pagar su balance para evitar inconviniente, ${cusUrl}`;

    //Message for disabled customers, this should be sent on the 1st of each Month.
    if (+balance > +monthly) {
      message = `Saludo ${firstName}, quiero informarle que su cuenta de WPrestamos esta bloqueada por falta de pago, su balance a pagar es ${printAmount(
        balance
      )} pesos!`;
    }

    //Message for brand new customers, first invoice created.
    if (+balance === +monthly && !lastPaidDate) {
      message = `Saludo ${firstName}, quiero informarle que WPrestamos le facturo su cuenta por primera vez, su balance a pagar es ${printAmount(
        balance
      )} pesos, ${cusUrl}`;
    }

    return message;
  };

  const handleSmsClick = () => {
    // e.preventDefault();
    let url = `sms:1${modalData.phone}&body=`;

    if (isAndroid) {
      url = `sms:1${modalData.phone}?body=`;
    }

    handleNavigate(url);
  };

  const handleWhatsappClick = () => {
    // e.preventDefault();
    const url = `https://wa.me/1${modalData.phone}?text=`;
    handleNavigate(url);
  };

  const handleNavigate = (url) => {
    const message = getMessage(modalData);

    // console.log(url + message);

    if (process.env.NODE_ENV === "development") {
      console.log(message);
      return;
    }

    window.location.href = url + message;
  };

  return (
    <>
      {balanceAmount === 0 ? (
        <div className="container mt-5 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Clientes con balance!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            En esta pagina puede ver todo los clientes con balance.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, no hay clientes con balance!
          </Typography>
        </div>
      ) : (
        <div className="mt-3">
          <Box component="div" p={1}>
            <Typography variant="h6" component="h4">
              <span>
                Balance pendiente: ({BalanceCount}) {printAmount(balanceAmount)}
              </span>
            </Typography>

            <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar Prestamo..." value={searchText} />
          </Box>

          <div className="mb-5">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Balance</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((customer) => (
                  <tr key={customer.customer_id}>
                    <td>
                      <Typography variant="subtitle2" component="p" className="font-weight-bold">
                        <Link style={{ color: "#000" }} to={`/payment/${customer.customer_id}`}>
                          <PersonIcon /> {customer.name}
                        </Link>
                      </Typography>
                    </td>

                    <td className={getClassName(customer).className}>{getClassName(customer).balance}</td>
                    <td>
                      <BalanceMenu customer={customer} handleDataClick={handleDataClick} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Modal
        show={modalData.name ? true : false}
        onHide={() => setModalData({})}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header>
          <Modal.Title>
            <Typography variant="h6">{modalData.name}</Typography>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Typography variant="h6" component="h3" gutterBottom>
            Cliente W{modalData.customer_id}
          </Typography>

          <ul className="list-group list-group-flush">
            <Typography variant="body1" component="h4">
              <li className="list-group-item">
                &Uacute;ltimo Pago:{" "}
                {modalData.lastPaidDate
                  ? printDate(parseISO(modalData.lastPaidDate), "dd-MMM-yy hh:mm:ss aaa")
                  : "Ninguno Ahun"}
              </li>
            </Typography>

            <Typography variant="body1" component="h4">
              <li className="list-group-item">Mensualidad: {printAmount(modalData.monthly)}</li>
            </Typography>

            <Typography variant="body1" component="h4">
              <li className="list-group-item">Balance Pendiente: {printAmount(modalData.balance)}</li>
            </Typography>
          </ul>

          <Box paddingTop={2}>
            <Typography variant="h6" component="h4" gutterBottom>
              Contactar Cliente!
            </Typography>

            <Box component={"div"} padding={1}>
              <Button
                size="small"
                startIcon={<FcCallback />}
                fullWidth
                variant="outlined"
                color="primary"
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = concatPhoneLink(modalData.phone);
                }}>
                Llamar Cliente!
              </Button>
            </Box>

            <Box component={"div"} padding={1}>
              <Button size="small" fullWidth variant="outlined" color="default" onClick={handleWhatsappClick}>
                <span className="text-success">
                  <BsWhatsapp size="1.4em" /> Mensaje de WhatsApp!
                </span>
              </Button>
            </Box>

            <Box component={"div"} padding={1}>
              <Button size="small" fullWidth variant="outlined" color="default" onClick={handleSmsClick}>
                <span className="text-secondary">
                  <FcSms size="1.4em" /> Mensaje de Texto!
                </span>
              </Button>
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setModalData({})}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>

      <br />
      <BottomNavigationPanel customers={customers} />
    </>
  );
};

export default Balance;

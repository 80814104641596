import { useEffect, useContext } from "react";
import { Context } from "../context/DataContext";
// import { Redirect } from "react-router-dom";
import { useHistory } from "react-router-dom";

const ResolveAuth = () => {
  const history = useHistory();
  const { restoreAppState } = useContext(Context);
  // const { route } = state;

  useEffect(() => {
    console.log("AppWorkflow - useEffect from ResolveAuth.js");
    restoreAppState(history.push);

    //eslint-disable-next-line
  }, []);

  // return route.creditor_id === 0 ? <Redirect to="/dashboard" /> : null;
  return null;
};

export default ResolveAuth;

import { useContext } from "react";
import { useParams } from "react-router";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import { Context } from "../context/DataContext";
import DebtorNameHeader from "../components/DebtorNameHeader";
import RenderPaymentForm from "../components/RenderPaymentForm";
import RenderPaymentInfo from "../components/RenderPaymentInfo";
import DisplayLastPaymentAlert from "../components/DisplayLastPaymentAlert";
import Loading from "../components/Loading";
import { UseCustomerList, UsePaymentList } from "../server/fetcher";
import { useHistory } from "react-router-dom";
// import { useSWRConfig } from "swr";

const PaymentCreate = () => {
  const history = useHistory();
  // const { mutate: globalMutate } = useSWRConfig();
  const { state, createPayment } = useContext(Context);
  const { id: customer_id } = useParams();
  const { user } = state;

  const { data: customers, mutate, isLoading } = UseCustomerList(user.user_id);
  const { data: payments, mutate: mutate2 } = UsePaymentList(user.user_id);

  const currentCustomer = customers?.find((c) => c.customer_id === +customer_id);
  const currentPayments = payments?.filter((c) => c.invoice_id === currentCustomer.invoice_id);

  if (!currentCustomer || isLoading) return <Loading />;

  const createPaymentHandlder = async (data) => {
    const res = await createPayment(data, customers);

    if (res === "success") {
      history.replace("/dashboard");

      await mutate();
      await mutate2();
    }
  };

  return (
    <div className="mt-3 mb-5">
      <Container maxWidth="md" disableGutters>
        <DebtorNameHeader debtor={currentCustomer}></DebtorNameHeader>

        {currentPayments.map((payment) => (
          <DisplayLastPaymentAlert key={payment.payment_id} payment={payment} />
        ))}

        <Box component="div" p={1}>
          <RenderPaymentInfo customer={currentCustomer} />
        </Box>

        <Box component="div" p={1}>
          <RenderPaymentForm
            customer={{
              ...currentCustomer,
              user_id: user.user_id,
            }}
            createPayment={createPaymentHandlder}
          />
        </Box>
      </Container>
    </div>
  );
};

export default PaymentCreate;

import { useEffect, useState } from "react";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import Typography from "@material-ui/core/Typography";
// import Badge from "@material-ui/core/Badge";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
// import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import { makeStyles } from "@material-ui/core/styles";
// import { FaHandHoldingUsd } from "react-icons/fa";
// import { GiTakeMyMoney, GiPayMoney } from "react-icons/gi";
// import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import { printAmount } from "../functions";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  stickToBottom: {
    backgroundColor: "#e4e4e4",
    width: "100%",
    position: "fixed",
    bottom: 0,
  },
});

const BottomNavigationPanel = ({ customers }) => {
  const history = useHistory();
  const classes = useStyles();
  const [monthly, setMonthly] = useState(0);
  const [collected, setCollected] = useState(0);
  const [balance, setBalance] = useState(0);
  // const [value, setValue] = useState("");

  useEffect(() => {
    if (!customers) return;
    setMonthly(
      customers.reduce((acc, obj) => {
        return +obj.monthly > 0 ? acc + +obj.monthly : acc;
      }, 0)
    );

    setCollected(
      customers.reduce((acc, obj) => {
        return +obj.paidAmount > 0 ? acc + +obj.paidAmount : acc;
      }, 0)
    );

    setBalance(
      customers.reduce((acc, obj) => {
        return +obj.balance > 0 && obj.inactive === 0 ? acc + +obj.balance : acc;
      }, 0)
    );
  }, [customers]);

  const handleChange = (event, newValue) => {
    // setValue(newValue);
  };

  return (
    <BottomNavigation showLabels onChange={handleChange} className={classes.stickToBottom}>
      <BottomNavigationAction
        onClick={() => history.push("/customers/monthly")}
        label="Mensual"
        value="entregas"
        icon={
          // <Badge badgeContent={misc.newLoansCount || 0} color="error">
          // <GiPayMoney size="1.6em" />
          <Typography variant="body1">{printAmount(monthly)}</Typography>
          //  </Badge>
        }
      />

      <BottomNavigationAction
        onClick={() => history.push("/collected/payments")}
        label="Cobrado"
        value="cobrado"
        icon={
          // <Badge badgeContent={misc.collectedCount || 0} color="primary">
          <Typography variant="body1">{printAmount(collected)}</Typography>
          // <GiTakeMyMoney size="1.8em" />
          // </Badge>
        }
      />

      <BottomNavigationAction
        // className={cashMoney < 0 ? "text-danger" : ""}
        onClick={() => history.push("/customers/balance")}
        label="Balance"
        value="efectivo"
        icon={<Typography variant="body1">{printAmount(balance)}</Typography>}
      />
    </BottomNavigation>
  );
};

export default BottomNavigationPanel;

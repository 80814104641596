import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core";
import { printDate, printAbsAmount, printAmount } from "../functions";
import Avatar from "react-avatar";
import { parseISO } from "date-fns";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(() => ({
  CardContent: {
    paddingTop: 0,
  },
}));

const CustomerCard = ({ customer }) => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Card raised className="mb-1">
      <CardHeader
        avatar={<Avatar size={30} name={customer.name} textSizeRatio={1.8} maxInitials={2} />}
        title={
          <Typography variant="h6" component="h4">
            {customer.name}
          </Typography>
        }
        subheader={
          <>
            <Typography variant="body1" component="p">
              &Uacute;ltimo Pago:{" "}
              {customer.lastPaidDate
                ? printDate(parseISO(customer.lastPaidDate), "dd-MMM-yy hh:mm:ss aaa")
                : "Ninguno Ahun"}
            </Typography>
          </>
        }
      />

      <CardContent className={classes.CardContent}>
        <Typography variant="body1" component="p">
          Monto Mensual: {printAmount(customer.monthly)}
        </Typography>

        <Typography variant="body1" component="p">
          Balance Pendiente: {printAbsAmount(customer.balance)}
        </Typography>
      </CardContent>

      <Divider />

      <CardActions disableSpacing>
        <Grid container>
          <Grid item xs={4}>
            <IconButton aria-label="add sector" onClick={() => history.push(`/payment/${customer.customer_id}`)}>
              <FaRegMoneyBillAlt size="1.1em" className="text-success" />
            </IconButton>
            {/* <IconButton aria-label="share" onClick={() => history.push(`/debtor/profile/${customer.debtor_id}`)}>
              <PersonIcon />
            </IconButton> */}
          </Grid>

          {!customer.lastPaidDate ? (
            <Grid item xs={8} align="right">
              <Typography variant="subtitle2" component="p" className="badge badge-pill badge-primary">
                <i>
                  <small>Nuevo</small>
                </i>
              </Typography>
            </Grid>
          ) : (
            <>
              {customer.paidAmount > 0 && (
                <Grid item xs={8} align="right">
                  <Typography variant="subtitle2" component="p" className="badge badge-pill badge-success">
                    <i>
                      <small>Pago {printAmount(customer.paidAmount)}!</small>
                    </i>
                  </Typography>
                </Grid>
              )}

              {+customer.credit > 0 && (
                <Grid item xs={8} align="right">
                  <Typography variant="subtitle2" component="p" className="badge badge-pill badge-info">
                    <small>Credito {printAmount(customer.credit)}!</small>
                  </Typography>
                </Grid>
              )}
            </>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
};

export default CustomerCard;

import Typography from "@material-ui/core/Typography";
// import Alert from "@material-ui/lab/Alert";
import { printAmount, printDate, newDate } from "../functions";

const DisplayLastPaymentAlert = ({ payment = {} }) => {
  if (+payment.paymentAmount === 0) return null;

  return (
    <>
      <div style={{ background: "#7ddd81" }} className="alert">
        <Typography variant="body2" component="p">
          Pago aplicado {printAmount(payment.paymentAmount)} en {printDate(newDate(payment.paymentDate))}!
        </Typography>
      </div>
    </>
  );
};

export default DisplayLastPaymentAlert;

import React, { useState } from "react";
import Box from "@material-ui/core/Box";
import { AiOutlineWhatsApp, AiOutlineCopy } from "react-icons/ai";
import { FcCheckmark } from "react-icons/fc";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { shareTextToWhatsApp } from "share-text-to-whatsapp";
// import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const RenderShareButtons = ({ message, messageWhatsApp }) => {
  const [copied, setCopied] = useState(false);
  const handleWhatsApp = (message) => {
    shareTextToWhatsApp(message);
  };

  return (
    <div>
      <Box paddingRight={1} component="span" display="inline" className="Demo__some-network">
        <CopyToClipboard text={message} onCopy={() => setCopied(true)}>
          {!copied ? <AiOutlineCopy size="1.6em" /> : <FcCheckmark size="1.6em" />}
        </CopyToClipboard>
      </Box>

      <Box
        paddingRight={1}
        onClick={() => handleWhatsApp(messageWhatsApp)}
        component="span"
        display="inline"
        className="text-success">
        <AiOutlineWhatsApp size="1.6em" />
      </Box>
    </div>
  );
};

export default RenderShareButtons;

import { useContext, useState, useEffect } from "react";
import { Context } from "../context/DataContext";
import SearchBox from "../components/common/SearchBox";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import PersonIcon from "@material-ui/icons/Person";
import { RemoveAccents, printAmount } from "../functions";
import CollectedMenu from "../components/CollectedMenu";
import { Link } from "react-router-dom";
import BottomNavigationPanel from "../components/BottomNavigationPanel";
import { UsePaymentList, UseCustomerList } from "../server/fetcher";
import Loading from "../components/Loading";
import Modal from "react-bootstrap/Modal";
// import SubmitButton from "../components/SubmitButton";
// import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

// export function sortArrayObjs(arr, prop1, prop2) {
//   let sort1 = [...arr].sort(function (a, b) {
//     if (a[prop1] === b[prop1]) {
//       if (a[prop2] === b[prop2]) return 0;
//       return a[prop2] < b[prop2] ? -1 : 1;
//     } else {
//       return a[prop1] < b[prop1] ? -1 : 1;
//     }
//   });
//   return sort1;
// }

const Collected = () => {
  const { state, deletePayment } = useContext(Context);
  const { user } = state;
  // const [currentData, setCurrentData] = useState([]);
  const [collectedAmount, setCollectedAmount] = useState([]);
  const [collectedCount, setCollectedCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [modalData, setModalData] = useState({});

  const { data: customers, isLoading } = UseCustomerList(user.user_id);
  const { data: payments, isLoading: isLoading2 } = UsePaymentList(user.user_id);

  useEffect(() => {
    if (!payments) return;

    // const current = payments.filter((x) => +x.paymentAmount > 0);
    // setCurrentData(sortArrayObjs(current, "lastPaidDate", "paymentAmount").reverse());

    setCollectedAmount(
      payments.reduce((acc, obj) => {
        return +obj.paymentAmount > 0 ? acc + +obj.paymentAmount : acc;
      }, 0)
    );

    setCollectedCount(
      payments.reduce((acc, obj) => {
        return +obj.paymentAmount > 0 ? acc + 1 : acc;
      }, 0)
    );

    //eslint-disable-next-line
  }, [payments]);

  // const handleDeletePayment = async (modalData) => {
  //   const { payment_id, invoice_id, customer_id } = modalData;
  //   await deletePayment({ payment_id, invoice_id, customer_id });
  // };

  if (isLoading || isLoading2) return <Loading />;

  const filterCustomers = (payments) => {
    let filtered = payments;

    if (searchText.length > 2) {
      filtered = filtered.filter((m) => RemoveAccents(m.search).toUpperCase().indexOf(searchText.toUpperCase()) >= 0);
      return filtered.slice(0, 5);
    }

    return filtered.slice(0, 200);
  };

  const handleDataClick = (data) => {
    setModalData(data);
  };

  const handleSearch = (query) => {
    setSearchText(query);
  };

  const filtered = filterCustomers(payments);

  return (
    <>
      {collectedAmount === 0 ? (
        <div className="container mt-5 mb-5">
          <Typography variant="h5" componet="h3" gutterBottom>
            Clientes Cobrado!
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            En esta pagina puede ver todo los pagos aplicado.
          </Typography>
          <Typography variant="body1" componet="p" paragraph>
            Por el momento, no ha aplicado ningun Pago!
          </Typography>
        </div>
      ) : (
        <div className="mt-3">
          <Box component="div" p={1}>
            <Typography variant="h6" component="h4">
              <span>
                Clientes Cobrado: ({collectedCount}) {printAmount(collectedAmount)}
              </span>
            </Typography>

            <SearchBox onChange={handleSearch} placeHolder="Escriba para buscar Prestamo..." value={searchText} />
          </Box>

          <div className="mb-5">
            <table className="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Nombre</th>
                  <th scope="col">Cantidad</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {filtered.map((customer) => (
                  <tr key={customer.payment_id}>
                    <td>
                      <Typography variant="subtitle2" component="p" className="font-weight-bold">
                        <Link style={{ color: "#000" }} to={`/payment/${customer.customer_id}`}>
                          <PersonIcon /> {customer.name}
                        </Link>
                      </Typography>
                    </td>
                    <td>{printAmount(customer.paymentAmount)}</td>
                    <td>
                      <CollectedMenu customer={customer} handleDataClick={handleDataClick} />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <Modal
        show={modalData.name ? true : false}
        onHide={() => setModalData({})}
        backdrop="static"
        keyboard={false}
        centered>
        <Modal.Header>
          <Modal.Title>
            <Typography variant="h6">
              {modalData.name}
              {/* <b className="p-2">
                    <Chip label={`C${modalData.debtor_id}`} />
                  </b> */}
            </Typography>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Typography variant="h6" component="h3" className="text-danger">
            Confirmaci&oacute;n
          </Typography>

          <Typography variant="h6" component="h4" gutterBottom></Typography>

          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <Typography variant="body1" component="h4">
                Cantidad de Pago {printAmount(modalData.paymentAmount)}
              </Typography>
            </li>
          </ul>

          <Box paddingTop={2}>
            <Typography variant="h6" component="h4" gutterBottom>
              Decea borrar este Pago?
            </Typography>
            <br />

            {/* <SubmitButton
              startIcon={<DeleteForeverIcon />}
              color="secondary"
              text="Borrar Pago"
              callBack={() => handleDeletePayment(modalData)}
              autoFetch={false}
            /> */}
          </Box>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setModalData({})}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>

      <br />
      <BottomNavigationPanel customers={customers} />
    </>
  );
};

export default Collected;

// import { StrictMode } from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import register from "./registerServiceWorker";
// import reportWebVitals from "./reportWebVitals";
import { Provider } from "./context/DataContext";
import "bootstrap/dist/css/bootstrap.css";
import pkg from "../package.json";

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://5ad9db49a45e4243910afe5f5c4ab96a@o637002.ingest.sentry.io/6631990",
    release: "admin-" + pkg.version,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <Provider>
    <BrowserRouter>
      {/* <StrictMode> */}
      <App />
      {/* </StrictMode> */}
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

import { Form, RenderInput, RenderCheckboxLabel } from "./Form";
import Box from "@material-ui/core/Box";
import { string, object } from "yup";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import { BiLogIn } from "react-icons/bi";
import pkg from "../../package.json";
import { localStorageRememberUser } from "../config";

const RenderLoginForm = ({ submithandler }) => {
  const validation = object({
    username: string().required("Usuario es requerido!"),
    password: string().required("Contraseña es requerido!"),
  });

  let jsonValue = localStorage.getItem(localStorageRememberUser);
  const deviceObj = jsonValue != null ? JSON.parse(jsonValue) : null;

  const initialValues = {
    rememberUsername: true,
    username: deviceObj?.username,
    password: "",
  };

  const handleSubmit = async (data) => {
    await submithandler(data);
  };

  const button = { icon: <BiLogIn />, label: "Iniciar Sesion", variant: "contained", color: "primary" };

  return (
    <div>
      <div className="mt-2 mb-1 text-center">
        <img alt="" src="/../../apple-touch-icon-144x144.png" />
      </div>

      {deviceObj?.firstname ? (
        <>
          <Typography variant="h5" component="h3" gutterBottom>
            Iniciar Sesion
          </Typography>
          <Typography variant="h6" component="h4" paragraph>
            Bienvenido, {deviceObj.firstname}!
          </Typography>
        </>
      ) : (
        <Typography variant="h5" component="h3" gutterBottom>
          Iniciar Sesion
        </Typography>
      )}

      <Form initialValues={initialValues} validation={validation} submithandler={handleSubmit} button={button}>
        <Box paddingBottom={2}>{RenderInput("username", "Usuario", "text", false, "outlined")}</Box>
        <Box paddingBottom={1}>{RenderInput("password", "Contraseña", "password", false, "outlined")}</Box>
        <Box paddingBottom={1}>{RenderCheckboxLabel("rememberUsername", "Recordar mi usuario!")}</Box>
      </Form>

      <Divider className="mt-4" />

      <h6 className="text-center font-italic" style={{ marginTop: "30px", marginBottom: "30px" }}>
        <Typography variant="subtitle2" component="p">
          App Version {pkg.version}
        </Typography>
      </h6>
    </div>
  );
};

export default RenderLoginForm;

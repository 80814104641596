import { useState, useContext } from "react";
import { Context } from "../context/DataContext";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
// import Badge from "@material-ui/core/Badge";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
// import AccountCircle from "@material-ui/icons/AccountCircle";
// import NotificationsIcon from "@material-ui/icons/Notifications";
import MoreIcon from "@material-ui/icons/MoreVert";
// import MailIcon from "@material-ui/icons/Mail";
import MUIDrawer from "@material-ui/core/Drawer";
// import List from "@material-ui/core/List";
import MenuList from "@material-ui/core/MenuList";
// import ListItem from "@material-ui/core/ListItem";
// import Brightness7Icon from "@material-ui/icons/Brightness7";
// import Brightness6Icon from "@material-ui/icons/Brightness6";
import ListItemIcon from "@material-ui/core/ListItemIcon";
// import CheckIcon from "@material-ui/icons/Check";
// import BrightnessAutoIcon from "@material-ui/icons/BrightnessAuto";
// import GroupAddIcon from "@material-ui/icons/GroupAdd";
import ListItemText from "@material-ui/core/ListItemText";
import { DrawerList } from "./DrawerList";
import { Link } from "react-router-dom";
// import LoginDialog from "./LoginDialog";
import InternetConection from "./InternetConection";
import { AiOutlineUsergroupAdd, AiOutlineQuestionCircle, AiOutlinePoweroff } from "react-icons/ai";
// import {  AiOutlineMail } from "react-icons/ai";
import { FaRegUser } from "react-icons/fa";
// import { IoMdNotificationsOutline } from "react-icons/io";
import InstallPWA from "../hooks/InstallPwa";
// import ChangeRouteModal from "./ChangeRouteModal";
import BluetoothIcon from "@material-ui/icons/Bluetooth";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  drawerHeader: {
    paddingLeft: 5,
    paddingRight: 10,
    paddingBottom: 10,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  selected: {
    backgroundColor: "#7f8691 !important",
    color: "white",
    fontWeight: 600,
  },

  title: {
    flexGrow: 1,
  },

  inputRoot: {
    color: "inherit",
  },

  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export default function PrimarySearchAppBar() {
  const history = useHistory();
  const { state, logout } = useContext(Context);
  const { user } = state;

  const classes = useStyles();
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  // const [mobileMoreAnchorEl2, setMobileThemeAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  // const isMobileMenuOpen2 = Boolean(mobileMoreAnchorEl2);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  // const handleMobileThemeMenuClose = () => {
  //   setMobileThemeAnchorEl(null);
  // };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  // const handleMobileThemeMenuOpen = (event) => {
  //   setMobileThemeAnchorEl(event.currentTarget);
  // };

  const handleMobileNavigation = (to) => {
    handleMobileMenuClose();
    if (to === "/") {
      logout(() => history.replace("/"));
    } else {
      history.push(to);
    }
  };

  // const handleMobileThemeClicks = () => {
  //   toggleDarkMode();
  //   handleMobileThemeMenuClose();
  // };

  // const handleNavigation = (to) => {
  //   toggleDrawer();
  //   history.push(to);
  // };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const menuId = "primary-search-account-menu";

  const mobileMenuId = "primary-search-account-menu-mobile";
  // const mobileMenuId2 = "primary-search-account-menu-mobile2";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      {/* <MenuItem>
        <IconButton aria-label="show 0 new mails" color="inherit">
          <Badge badgeContent={0} color="secondary">
            <AiOutlineMail />
          </Badge>
        </IconButton>
        Mensajes
      </MenuItem>

      <MenuItem>
        <IconButton aria-label="show 0 new notifications" color="inherit">
          <Badge badgeContent={0} color="secondary">
            <IoMdNotificationsOutline />
          </Badge>
        </IconButton>
        Notificaciones
      </MenuItem> */}

      {/* <MenuItem onClick={() => handleMobileNavigation("/quickprinter")}>
        <IconButton aria-label="show 0 new notifications" color="inherit">
          <BluetoothIcon />
        </IconButton>
        Conectar Impresora
      </MenuItem>

      <MenuItem onClick={() => handleMobileNavigation("/referrals")}>
        <IconButton aria-label="show 0 new referrals" color="inherit">
          <AiOutlineUsergroupAdd size="1em" />
        </IconButton>
        Referidos
      </MenuItem>

      <MenuItem onClick={() => handleMobileNavigation("/profile")}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <FaRegUser size="0.8em" />
        </IconButton>
        Mi Perfil
      </MenuItem> */}

      {/* <Divider /> */}

      <MenuItem className="text-danger" onClick={() => handleMobileNavigation("/")}>
        <IconButton
          aria-label="logout"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <AiOutlinePoweroff size="0.8em" />
        </IconButton>
        Salir
      </MenuItem>

      {/* <Divider />

      <MenuItem onClick={() => handleMobileNavigation("/contact")}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit">
          <AiOutlineQuestionCircle />
        </IconButton>
        Contactarme
      </MenuItem> */}
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <InternetConection />
      <AppBar style={{ marginLeft: -1, marginTop: -1 }} position="static">
        {user.user_id !== 0 ? (
          <Toolbar>
            <IconButton
              onClick={toggleDrawer}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer">
              <MenuIcon />
            </IconButton>
            <Typography onClick={() => history.push("/dashboard")} className={classes.title} variant="h6" noWrap>
              WPrestamos
            </Typography>

            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              {/* 				
              <IconButton edge="end" aria-label="show 0 new mails" color="inherit">
              <Badge badgeContent={0} color="secondary">
              <AiOutlineMail />
              </Badge>
              </IconButton>
              <IconButton edge="end" aria-label="show 0 new notifications" color="inherit">
              <Badge badgeContent={0} color="secondary">
              <IoMdNotificationsOutline />
                </Badge>
              </IconButton> */}

              <IconButton
                edge="end"
                onClick={() => history.push("/referrals")}
                aria-label="show 0 new referrals"
                color="inherit">
                <AiOutlineUsergroupAdd size="1em" />
              </IconButton>

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => history.push("/quickprinter")}
                color="inherit">
                <BluetoothIcon />
              </IconButton>

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => history.push("/profile")}
                color="inherit">
                <FaRegUser size="0.8em" />
              </IconButton>

              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={() => history.push("/contact")}
                color="inherit">
                <AiOutlineQuestionCircle />
              </IconButton>
            </div>

            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit">
                <MoreIcon />
              </IconButton>
            </div>

            {/* <div>
              <IconButton
                edge="end"
                aria-label="Admin Panel"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMobileThemeMenuOpen}
                color="inherit">
                {state.device.darkMode === true ? <Brightness7Icon /> : <Brightness6Icon />}
              </IconButton>
            </div> */}
          </Toolbar>
        ) : (
          <Toolbar>
            <Typography onClick={() => history.push("/")} className={classes.title} variant="h6" noWrap>
              Bienvenido
            </Typography>
          </Toolbar>
        )}
      </AppBar>

      {process.env.REACT_APP_ENV === "testing" && (
        <div className="text-center mt-2 font-italic text-warning">
          <h6>Testing</h6>
        </div>
      )}

      <MUIDrawer
        className={classes.drawer}
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        classes={{
          paper: classes.drawerPaper,
        }}>
        <div className={classes.drawerHeader}>
          <div className="text-center">
            <img
              alt="app-logo"
              style={{ width: 165, height: 93, marginBottom: 20, marginTop: 15 }}
              src="/../../full-logo-copy.png"
            />
          </div>
        </div>
        <Divider />

        <MenuList>
          <div onClick={() => toggleDrawer()}>
            {DrawerList.map((item, index) => {
              return (
                <div key={index}>
                  <MenuItem
                    component={Link}
                    to={item.path}
                    classes={{ selected: classes.selected }}
                    selected={history.location.pathname === item.path}>
                    <ListItemIcon>{item.icon}</ListItemIcon>
                    <ListItemText primary={item.title} />
                  </MenuItem>
                </div>
              );
            })}
          </div>
        </MenuList>
      </MUIDrawer>
      <InstallPWA />
      {renderMobileMenu}
    </div>
  );
}

import { useState, useEffect } from "react";
import { number, date, object } from "yup";
import { Form, RenderInput } from "./Form";
// import { useFormikContext } from "formik";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import BackspaceIcon from "@material-ui/icons/Backspace";
import { toast } from "react-toastify";
import Grow from "@material-ui/core/Grow";
import { printAmount } from "../functions";
import { FaRegMoneyBillAlt } from "react-icons/fa";
// import TextField from "@material-ui/core/TextField";

// const GetFormikValues = ({ setValues }) => {
//   const { values } = useFormikContext();
//   useEffect(() => {
//     setValues(values);
//   }, [values, setValues]);
//   return null;
// };

// const initialFormValues = { todayDate: null };

const RenderPaymentForm = ({ customer, createPayment }) => {
  const [paymentAmount, setPaymentAmount] = useState(0);
  // const [referralPayment, setReferralPayment] = useState(0);
  const [duplexPayment, setDuplexPayment] = useState(false);
  const [highPaymentAmount, setHighPaymentAmount] = useState(false);
  const [todayDate, setTodayDate] = useState("");
  // const [formValues, setFormValues] = useState(initialFormValues);

  useEffect(() => {
    const utc = new Date().toJSON().slice(0, 10);
    setTodayDate(utc);
    //eslint-disable-next-line
  }, []);

  //Disabled referral payment box.
  // useEffect(() => {
  //   if (customer) {
  //     setReferralPayment(+customer.referral);
  //   }
  // }, [customer]);

  // const handleReferralPayment = (event) => {
  //   if (!event.target.value) {
  //     setReferralPayment("");
  //     return;
  //   }

  //   const value = event.target.value * 1;
  //   setReferralPayment(value);
  // };

  const handlePaymentAmount = (amount) => {
    setPaymentAmount(amount);
  };

  const handleTextboxChange = (event) => {
    if (!event.target.value) {
      setPaymentAmount("");
      return;
    }

    setPaymentAmount(event.target.value * 1);
  };

  const handleClearInput = () => {
    setPaymentAmount("");
  };

  const initialValues = {
    customer_id: customer.customer_id,
    invoice_id: customer.invoice_id,
    todayDate: todayDate,
    balance: customer.balance,
    // monthly: customer.monthly,
    // name: customer.name,
    paymentAmount,
    // referralPayment: 0, // this was forced to 0
  };

  const validation = object({
    paymentAmount: number().required("Cantidad es requerida!").min(1, "Cantidad es muy corta!"),
    todayDate: date().required("Fecha de pago es requerida!"),
  });

  const button = {
    icon: <FaRegMoneyBillAlt size="1.2em" />,
    label: "Aplicar Pago",
    variant: "contained",
    color: "primary",
  };

  const submithandler = async (data) => {
    if (+paymentAmount < 1) {
      // if (+paymentAmount + +referralPayment < 1) {
      toast.error(`Cantidad Invalida, revise la cantidad de Pagos!`);
      return;
    }

    if (customer.paidAmount && +customer.paidAmount !== 0 && !duplexPayment) {
      setDuplexPayment(true);
      return;
    }

    if (+customer.paidAmount > customer.monthly * 2 && !highPaymentAmount) {
      setHighPaymentAmount(true);
      return;
    }

    await createPayment(data);
  };

  return (
    <div>
      <Form initialValues={initialValues} validation={validation} submithandler={submithandler} button={button}>
        <Box paddingBottom={3}>{RenderInput("todayDate", "Fecha de Pago  ", "date", false, "outlined")}</Box>

        <Box paddingBottom={2}>
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Cobrar Cantidad</InputLabel>
            <OutlinedInput
              autoComplete="off"
              margin="dense"
              placeholder="Escriba la cantidad de Pago!"
              type="number"
              name="paymentAmount"
              id="outlined-adornment-amount"
              value={paymentAmount}
              onChange={handleTextboxChange}
              startAdornment={<InputAdornment position="start">$</InputAdornment>}
              labelWidth={120}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClearInput}>
                    <BackspaceIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Box>

        <Box paddingBottom={1}>
          <Grid container spacing={1}>
            <Grid item xs={5}>
              <Button
                size="small"
                variant="outlined"
                color="default"
                onClick={() => handlePaymentAmount(Math.min(customer.monthly))}>
                <small>Pagar Monto</small>
              </Button>
            </Grid>

            <Grid item xs={5}>
              <Button
                size="small"
                variant="outlined"
                color="default"
                onClick={() => handlePaymentAmount(customer.balance)}>
                <small>Pagar Balance</small>
              </Button>
            </Grid>
          </Grid>
        </Box>

        {highPaymentAmount && (
          <Box paddingBottom={1}>
            <Grow direction="up" in>
              <Alert variant="outlined" severity="info">
                <Typography variant="body1" component="p">
                  Esta seguro(a) que quiere aplicar pago de {printAmount(customer.paidAmount)}? Para continuar trate
                  nuevamente!
                </Typography>
              </Alert>
            </Grow>
          </Box>
        )}

        {duplexPayment && (
          <Box paddingBottom={1}>
            <Grow direction="up" in>
              <Alert variant="outlined" severity="warning">
                <Typography variant="body1" component="p">
                  Este cliente, <b>ya tiene un pago de {printAmount(customer.paidAmount)}</b>, si quiere aplicar le otro
                  pago trate nuevamente!
                </Typography>
              </Alert>
            </Grow>
          </Box>
        )}

        <div className="mt-2 alert  font-weight-bold">
          <strong>Aplicar Cantidad: {paymentAmount ? printAmount(paymentAmount) : "$0"}</strong>

          {/*  
          {+customer.referral > 0 && (
            <Box p={1}>
              <TextField
                onChange={handleReferralPayment}
                color="secondary"
                placeholder="Mora Cantidad!"
                label=""
                type="number"
                variant="outlined"
                value={referralPayment}
                id="standard-start-adornment"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <span>Pago de Referido $</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}
          */}
        </div>

        {/* <GetFormikValues setValues={setFormValues} /> */}
      </Form>
    </div>
  );
};

export default RenderPaymentForm;
